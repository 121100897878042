<!--
  作者：顾先生
-->
<template>
  <div>
    <div id="bottom" class="clearfix">
      <div class="bottomBox">
        <p class="bottom_p1">关于我们</p>
        <p class="bottom_p2">中雄世纪征信有限公司是经备案的信用服务机构，主要为中小企业提供企业信用服务。</p>
        <div class="bottomXuanze">
          <div class="bottomXuanze1" @click="GongShang">
            <img src="../../assets/bottomImg/ji.png" class="bottomXuanze_img1">
            <span class="bottomXuanze_span1">冀公网备案</span>
          </div>
          <div class="bottomXuanze1" @click="XinYong">
            <img src="../../assets/bottomImg/gongshang.png" class="bottomXuanze_img1">
            <span class="bottomXuanze_span1">工商登记信息</span>
          </div>
          <div class="bottomXuanze1" @click="beian">
            <img src="../../assets/bottomImg/jigou.png" class="bottomXuanze_img1">
            <span class="bottomXuanze_span1">机构备案信息</span>
          </div>
        </div>
        <div class="bottomLianxi">
          <p class="bottomLianxi_p1" @click="telPhone(4000642818)">联系电话:400-064-2818</p>
          <p class="bottomLianxi_p2">公众号:守信企业计划</p>
        </div>
      </div>
    </div>
    <p class="bottom_p3">郑重提醒广大企业主，加入守信激励计划无需验证码等相关信息如需填写银行卡号、验证码等相关信息，切勿轻信，谨防上当受骗!</p>
    <p class="bottom_p4">C2016-2024 中雄世纪征信有限公司      版权所有 <br>备案号：冀ICP备20017876号-16</p>
    <p class="bottom_p5"></p>
    <van-overlay :show="GSshow">
      <div>
        <img src="../../assets/bottomImg/jiwangAn.png" class="GSshowImg">
        <van-button hairline class="GSshowBtn" @click="GSshow = false">关闭</van-button>
      </div>
    </van-overlay>

    <van-overlay :show="XYshow">
      <div>
        <img src="../../assets/bottomImg/tan1.jpg" class="GSshowImg">
        <van-button hairline class="GSshowBtn" @click="XYshow = false">关闭</van-button>
      </div>
    </van-overlay>

    <van-overlay :show="Beian">
      <div>
        <img src="../../assets/bottomImg/tan2.png" class="GSshowImg">
        <van-button hairline class="GSshowBtn" @click="Beian = false">关闭</van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'bottom',
  // 注册组件
  components: {},
  // 注册方法
  methods: {
    // 信用机构备案
    beian () {
      this.Beian = true
    },
    // 工商弹窗
    GongShang () {
      this.GSshow = true
    },
    // 信用弹窗
    XinYong () {
      this.XYshow = true
    },
    // 拨打电话
    telPhone (phoneNum) {
      window.location.href = 'tel://' + phoneNum
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      GSshow: false,
      XYshow: false,
      Beian: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {},
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.bottom_p3{
  margin-top: 4px;
  margin-left: -4px;
  text-align: center;
  width: 104%;
  font-size: 12px;
  color: #0054ff;
  line-height: 20px;
  transform: scale(.9);
}
.bottom_p4{
  text-align: center;
  margin-top: 2px;
  font-size: 12px;
  color: #333333;
  width: 100%;
}
.bottom_p5{
  width: 100%;
  height: 10px;
}
.GSshowImg{
  display: block;
  margin: 80px auto 0px;
  width: 97%;
  height: 468px;
}
.GSshowBtn{
  width: 200px;
  display: block;
  margin: 10px auto;
}
#bottom{
  margin-top: 15px;
  padding: 10px;
  width: 100%;
  background-color: #fcc41e;
  .bottomBox{
    padding: 10px;
    background-color: #ffffff;
    border: solid 2px #f6aa27;
    box-shadow: 0px 2px 5px 0px
    rgba(195, 153, 24, 0.2);
    border-radius: 4px;
    .bottom_p1{
      text-align: center;
      font-size: 17px;
      font-weight: 900;
      letter-spacing: 2px;
      color: #cf2020;
    }
    .bottom_p2{
      margin: 5px auto 0px;
      width: 320px;
      text-indent: 2em;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 20px;
      color: #cf2020;
    }
  }
  .bottomXuanze{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bottomXuanze1{
      display: flex;
      align-items: center;
    }
    .bottomXuanze_img1{
      width: 20px;
    }
    .bottomXuanze_span1{
      margin-left: 7px;
      font-size: 12px;
      color: #cf2020;
    }
  }
  .bottomLianxi{
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bottomLianxi_p1,
    .bottomLianxi_p2{
      font-size: 12px;
      color: #cf2020;
    }
  }
}
</style>
